:root {
  --spotify-green: #1db954;
  --spotify-darkgreen: #159543;
  --spotify-black: #141414;
  --spotify-gray: #a0a0a0;
  --spotify-error: #e01636;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 1vmin);
  color: white;
}

.App-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(16px + 1vmin);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
  padding: 3.25em .5em 2em;
  color: white;
}

body {
  background-color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-button {
  cursor: pointer;
  border: none;
  background: none;
}

svg.mute {
  fill: var(--spotify-gray);
  stroke: var(--spotify-gray);
}

svg.mute:hover {
  fill: white;
  stroke: white;
}

svg.mute:active {
  fill: var(--spotify-gray);
  stroke: white;
}

.rand-button-mobile {
  color: var(--spotify-black);
  cursor: pointer;
  font-size: calc(16px + 2vmin);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
  padding: 11px 13px;
  background: var(--spotify-green);
  margin: 1vh 1vw;
  border: 2px solid var(--spotify-green);
  border-radius: 8px;
  transition: background 0.1s ease-in-out;
  text-decoration: none;
  vertical-align: middle;
}

.rand-button {
  color: var(--spotify-black);
  cursor: pointer;
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
  padding: 11px 13px;
  background: var(--spotify-green);
  margin: 2vh 2vw 2vh 0vw;
  border: 2px solid var(--spotify-green);
  border-radius: 8px;
  transition: background 0.1s ease-in-out;
  text-decoration: none;
  vertical-align: middle;
}

.rand-button:hover {

  transform: scale(1.05);
}

.rand-button:active {
  background: var(--spotify-darkgreen);
  border-color: var(--spotify-darkgreen);
  transform: scale(1);
}

.explicit {
  color: var(--spotify-black);
  background-color: var(--spotify-gray);
  border-radius: 4px;
  padding: 6px 9px;
  font-size: 60%;
  font-weight: bold;
  vertical-align: middle;
}

.type {
  color: var(--spotify-gray);
}

.name {
  color: white;
  text-align: end;
  text-decoration: none;
}

.subtitle {
  color: white;
  font-size: calc(24px + 2vmin);
  font-weight: 900;
}

a.name:hover {
  text-decoration: underline;
}

.checkContainerMobile {
  font-size: calc(16px + 2vmin);
  margin: 2vh 1vw;
  
}

.checkContainer {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 20px;
  color: var(--spotify-gray);
  user-select: none;
  vertical-align: middle;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: transparent;
  border: 2px solid var(--spotify-gray);
  border-radius: 50%;
}

.checkContainer:hover {
  color: white;
}


.checkContainer:hover input ~ .checkmark {
  border-color: white;
}

.checkContainer:hover input:checked ~ .checkmark {
  border-color: white;
  background-color: white;
}


.checkContainer input:checked ~ .checkmark {
  background-color: var(--spotify-gray);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.checkContainer input:checked ~ .checkmark:after {
  display: block;
}


.checkContainer .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.infoContainer  {
  background-color: var(--spotify-black);
  border-radius: 20px;
  min-width: 70vw;
  max-width: 70vw;
  padding: 50px 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}

.infoContainerMobile {
  background-color: var(--spotify-black);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  min-width: 95%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorContainer {
  background-color: var(--spotify-error);
  color: white;
  font-size: 16px;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: center;
}

.header {
  position: fixed;
  border-bottom: solid 1px var(--spotify-black);
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 99;
  padding: 10px 20px 5px;
  background-color: black;
  text-align: left;
}

.spotifyLogo {
  position: absolute;
  right: 25px;
  top: 25px;
  height: 50px;
}

.spotifyLogoMobile {
  margin: 3vw;
  height: 6vw;
}

.controlContainer {
  background-color: var(--spotify-black);
  border-radius: 20px;
  padding: 3px 40px;
  margin: 10px;
}

.controlContainerMobile {
  background-color: var(--spotify-black);
  border-radius: 20px;
  padding: 3px 40px;
  margin: 10px;
  min-width: 95%;
}

.hoverScale {
  scale: 1.00;
}

.hoverScale:hover {
  scale: 1.02;
}

select {
  background-color: var(--spotify-black);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: var(--spotify-gray);
  border: solid 1px var(--spotify-gray);
  border-radius: 6px;
  padding: 10px;
  margin: 2vh 2vw 2vh 0vw;
  font-size: calc(16px);
}

select:hover {
  border-color: white;
  color: white;
}

select:focus {
  border-color: white;
  color: white;
}

