:root {
  --spotify-green: #1db954;
  --spotify-darkgreen: #159543;
  --spotify-black: #141414;
  --spotify-gray: #a0a0a0;
  --spotify-error: #e01636;
}

.App-mobile {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(14px + 1vmin);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
  padding: .8em .5em 2em;
  color: white;
}

.rand-button-mobile {
  color: var(--spotify-black);
  cursor: pointer;
  font-size: calc(16px + 2vmin);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
  padding: 11px 13px;
  background: var(--spotify-green);
  margin: 1vh 3vw 1vh 2vw;
  border: 2px solid var(--spotify-green);
  border-radius: 8px;
  transition: background 0.1s ease-in-out;
  text-decoration: none;
  vertical-align: middle;
}

.rand-button-mobile:hover {

  transform: scale(1.05);
}

.rand-button-mobile:active {
  background: var(--spotify-darkgreen);
  border-color: var(--spotify-darkgreen);
  transform: scale(1);
}

.infoContainerMobile {
  background-color: var(--spotify-black);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  width: 100%; /* Changed from min-width: 95% to width: 100% */
  box-sizing: border-box; /* Include padding in the total width */
  padding: 5% 0%; /* Adjusted padding to a smaller percentage */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.spotifyLogoMobile {
  margin: 4vw;
  height: 8vw;
}

.controlContainerMobile {
  background-color: var(--spotify-black);
  border-radius: 20px;
  width: 100%; /* Changed from min-width: 95% to width: 100% */
  box-sizing: border-box; /* Include padding in the total width */
  padding: 5%; /* Adjusted padding to a smaller percentage */
}

.nameMobile {
    color: white;
    text-align: end;
    text-decoration: underline;
}

